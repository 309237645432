// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint @typescript-eslint/no-shadow: off */
import {controller, target} from '@github/catalyst'
import {max as d3max, range} from 'd3-array'
import {scaleBand, scaleLinear} from 'd3-scale'
import {axisLeft} from 'd3-axis'
import d3Tip from '../d3/tip'
import {fetchPoll} from '../fetch'
import {format} from 'd3-format'
import memoize from '@github/memoize'
import {select} from 'd3-selection'

const cachedData = memoize(fetchData)

async function fetchData(url: string): Promise<Data> {
  const response = await fetchPoll(url, {headers: {accept: 'application/json'}})
  return await response.json()
}

@controller
class PulseAuthorsGraphElement extends HTMLElement {
  @target graph: HTMLElement

  async connectedCallback() {
    const graph = this.graph
    const url = graph.getAttribute('data-url')
    if (!url) {
      return
    }

    // Remove possibly existing cached graph.
    for (const el of graph.querySelectorAll('svg.viz')) {
      el.remove()
    }

    graph.classList.add('is-graph-loading')
    graph.classList.remove('is-graph-load-error', 'is-graph-empty')

    try {
      const data = await cachedData(url)
      graph.classList.remove('is-graph-loading')
      if (data != null && data.unusable) {
        graph.classList.add('is-graph-without-usable-data')
      } else if (
        (data != null && data.length === 0) ||
        (data != null && data.summary != null && data.summary.total === 0) ||
        (data[0] != null && data[0].weeks != null && data[0].weeks.length === 0)
      ) {
        graph.classList.add('is-graph-empty')
      } else {
        render(graph, data)
      }
    } catch (error) {
      graph.classList.remove('is-graph-loading')
      graph.classList.add('is-graph-load-error')
      throw error
    }
  }
}

const NUM_AUTHORS = 15

function render(container: Element, d: unknown[]) {
  const data = d.slice(0, +(NUM_AUTHORS - 1) + 1 || 9e9)
  const margin = {
    top: 20,
    right: 0,
    bottom: 30,
    left: 25
  }
  const width = Math.round(container.getBoundingClientRect().width) - margin.left - margin.right
  const height = Math.round(container.getBoundingClientRect().height) - margin.top - margin.bottom
  const x = scaleBand().domain(range(NUM_AUTHORS)).rangeRound([0, width]).padding(0.2)
  const y = scaleLinear()
    .domain([0, d3max(data, d => d.commits)])
    .range([height, 0])
  const yAxis = axisLeft(y)
    .ticks(3)
    .tickSize(-width)
    .tickFormat(d => (d < 1000 ? d : format(',~s')(d)))
  const tip = d3Tip()
    .attr('class', 'svg-tip')
    .offset([-10, 0])
    .html(function (d) {
      const author = d.login || d.name
      // eslint-disable-next-line github/unescaped-html-literal
      return `<strong>${
        d.commits
      }</strong> ${d.commits === 1 ? 'commit' : 'commits'} authored by <strong>${author}</strong>`
    })
  const vis = select(container)
    .append('svg')
    .attr('class', 'viz')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`)
    .call(tip)
  vis.append('g').attr('class', 'y axis').call(yAxis)
  const bars = vis
    .selectAll('.bar')
    .data(data)
    .enter()
    .append('g')
    .attr('class', `bar ${container.getAttribute('data-bar-class')}`)
    .attr('transform', (d, i) => `translate(${x(i)}, 0)`)
  bars
    .append('rect')
    .attr('width', x.bandwidth())
    .attr('height', d => height - y(d.commits))
    .attr('y', d => y(d.commits))
    .on('mouseover', tip.show)
    .on('mouseout', tip.hide)
  bars
    .append('a')
    .attr('xlink:href', function (d) {
      if (d.login != null) {
        return `/${d.login}`
      }
    })
    .attr('data-hovercard-type', 'user')
    .attr('data-hovercard-url', d => d.hovercard_url)
    .append('image')
    .attr('y', height + 5)
    .attr('alt', d => d.login || '')
    .attr('xlink:href', d => d.gravatar)
    .attr('width', x.bandwidth())
    .attr('height', x.bandwidth())
}
